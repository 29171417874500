.language_select {
  color: white;
  font-family: Inter, sans-serif;
  font-size: calc(min(11px, 0.8593750000000001vw));
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  z-index: 1000;
  @media screen and (max-width: 767px) {
    top: calc(min(20px, 5.089058524173027vw));
    right: calc(min(14px, 3.5623409669211195vw));
    font-size: calc(min(11px, 2.7989821882951653vw));
  }
  &__green {
    color: #518C70;
    & img {
      filter: invert(48%) sepia(33%) saturate(445%) hue-rotate(99deg) brightness(96%) contrast(88%);
    }
  }
  &__open {
    background-color: white;
  }
  &__green {
    color: #518C70;
    & img {
      filter: invert(48%) sepia(33%) saturate(445%) hue-rotate(99deg) brightness(96%) contrast(88%);
    }
  }
  &__main {
    position: relative;
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 calc(min(2px, 0.15625vw)) 0 rgba(0, 0, 0, 0.25);
    border-radius: calc(min(2px, 0.15625vw));
    @media screen and (max-width: 767px) {
      box-shadow: 0 0 calc(min(2px, 0.5089058524173028vw)) 0 rgba(0, 0, 0, 0.25);
      border-radius: calc(min(2px, 0.5089058524173028vw));
    }
    &__selected {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      height: calc(min(25px, 1.953125vw));
      width: calc(min(68px, 5.3125vw));
      @media screen and (max-width: 767px) {
        height: calc(min(25px, 6.369426751592357vw));
        width: calc(min(68px, 16.071428571428573vw));
      }
      &__text {
        margin-left: calc(min(4px, 0.3125vw));
        margin-right: calc(min(8px, 0.625vw));
        @media screen and (max-width: 767px) {
          margin-left: calc(min(4px, 1.0178117048346056vw));
          margin-right: calc(min(8px, 2.035623409669211vw));
        }
      }
      &__arrow {
        transition: all 0.3s ease-in-out;
        &-select {
          transform: rotate(180deg);
        }
      }
    }
    &__list {
      &__item {
        margin: calc(min(1px, 0.078125vw)) 0;
        padding: calc(min(2px, 0.15625vw)) calc(min(5px, 0.234375vw));
        cursor: pointer;
        @media screen and (max-width: 767px) {
          margin: calc(min(1px, 0.2544529262086514vw)) 0;
          padding: calc(min(2px, 0.5089058524173028vw)) calc(min(5px, 1.2722646310432568vw));
        }
        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
      }
      border-top: calc(min(1px, 0.078125vw)) solid rgba(255, 255, 255, 0.1);
      padding-top: calc(min(3px, 0.234375vw));
      padding-bottom: calc(min(3px, 0.234375vw));
      @media screen and (max-width: 767px) {
        border-top: calc(min(1px, 0.2544529262086514vw)) solid rgba(255, 255, 255, 0.1);
        padding-top: calc(min(3px, 0.7633587786259541vw));
        padding-bottom: calc(min(3px, 0.7633587786259541vw));
      }
    }
  }
}
