.payment-notification-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 15;
  &__content {
    background: #FFF;
    box-shadow: 0 calc(min(4px, 0.3125vw)) calc(min(4px, 0.3125vw)) 0 rgba(0, 0, 0, 0.25);
    padding: calc(min(10px, 0.78125vw)) calc(min(24px, 1.875vw));
    &__head {
      color: #518C70;
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: calc(min(12px, 0.9375vw));
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: calc(min(18px, 1.40625vw));
    }
    &__body {
      color: #585858;
      font-family: Inter, sans-serif;
      font-size: calc(min(14px, 1.09375vw));
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      max-width: calc(min(319px, 24.921875vw));
    }
    &__footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: calc(min(20px, 1.5625vw));
      & button {
          margin-left: calc(min(10px, 0.78125vw))
      }
    }
  }
}