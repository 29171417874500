@import url('https://fonts.googleapis.com/css2?family=Francois+One&display=swap');

.auth {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  &__logo {
    &__image {
      width: calc(min(85px, 6.640625vw));
    }
    &__text{
      color: #518C70;
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: calc(min(14px, 1.09375vw));
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  &__body {
    &__block {
      &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        & img {
          width: calc(min(220px, 17.1875vw));
        }
        &__text {
          margin-left: calc(min(8px, 0.625vw));
          color: var(--Main, #518C70);
          text-align: center;
          font-family: Francois One, sans-serif;
          font-size: calc(min(26px, 2.03125vw));
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      &__title {
        color: var(--Main, #518C70);
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: calc(min(12px, 0.9375vw));
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: calc(min(54px, 4.21875vw));
      }
      &__btn {
        border-radius: calc(min(3px, 0.234375vw));
        background: #518C70;
        padding: calc(min(9px, 0.703125vw)) calc(min(17px, 1.328125vw));
        color: #FFF;
        font-family: Inter, sans-serif;
        font-size: calc(min(12px, 0.9375vw));
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        width: max-content;
        cursor: pointer;
        display: flex;
        align-items: center;
        height: calc(min(26px, 2.03125vw));
        & svg {
          margin-right: calc(min(15px, 1.171875vw));
        }
      }
      &__description {
        margin-top: calc(min(29px, 2.265625vw));
        color: #838383;
        font-family: Inter, sans-serif;
        font-size: calc(min(12px, 0.9375vw));
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}