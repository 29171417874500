@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.templates {
  --initial-font-size: 3.90625vw;

  --in-progress-background: #FFFFFF;
  --in-progress-head-background: #6B0B18;
  --in-progress-head-color: #FFFFFF;
  --in-progress-number-color: #FFFFFF;
  --in-progress-number-bg-color: #FFFFFF;
  --in-progress-more-color: #FFFFFF;

  --done-background: #CAEFF8;
  --done-head-background: #183E30;
  --done-head-color: #2F50E5;
  --done-number-color: #2F50E5;
  --done-number-bg-color: #2F50E5;
  --done-more-color: #2F50E5;
  --head-font-size: 1.08em;

  --number-font-size: 5.015625vw;

  --count-columns: 3;

  --more-font-size: .34em;

  --number-background-width: 2em;

  &__text--active {
    text-decoration: underline;
  }

  &__original {
    position: relative;
    font-family: "Inter", sans-serif;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    text-align: start;
    font-size: var(--initial-font-size);

    &__line {
      height: calc(100% - (calc(var(--head-font-size) * 0.5) * 2));
      width: .1rem;
      background-color: rgba(0, 0, 0, .1);
      position: absolute;
      left: 50%;
      top: calc(var(--head-font-size) * 0.5);
      transform: translateX(-50%);
      z-index: 8;
    }

    &__in_progress {
      background: var(--in-progress-background);
      position: relative;

      .templates__original__block__head {
        color: var(--in-progress-head-color);
        font-weight: bold;
        z-index: 10;
        position: relative;
        background-color: var(--in-progress-head-background);
      }

      .templates__original__block__numbers {
        color: var(--in-progress-number-color);
        .templates__original__block__numbers__number__item {
          background-color: var(--in-progress-number-bg-color);
        }
      }

      .templates__more {
        color: var(--in-progress-more-color);
        font-size: var(--more-font-size);
      }
    }

    &__done {
      background: var(--done-background);

      .templates__original__block__head {
        color: var(--done-head-color);
        font-weight: 800;
      }

      .templates__original__block__numbers {
        color: var(--done-number-color);
        .templates__original__block__numbers__number__item {
            background-color: var(--done-number-bg-color);
        }
      }

      .templates__more {
        color: var(--done-more-color);
        font-size: var(--more-font-size);
      }

    }

    &__block {
      width: 50%;
      height: calc(100% - 0.78125% - 2.5%);
      padding: 0 2.5% 2.5%;
      position: relative;

      &__head {
        font-size: var(--head-font-size);
        position: relative;
        z-index: 10;
        text-align: center;
        margin-top: .5em;
        margin-bottom: .7em;
        padding: 2% 0;
        border-radius: .2em;
        box-shadow: 0 calc(var(--head-font-size) * 0.1) calc(var(--head-font-size) * 0.1) 0 rgba(0, 0, 0, 0.25);
        background-color: var(--done-head-background);
      }

      &__numbers {
        margin-top: 1.7187500000000002%;
        font-size: var(--number-font-size);
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        height: calc(79% - 30px);
        overflow: hidden;

        &__number {
          width: calc(100% / var(--count-columns));
          font-weight: bold;
          & span {
            overflow:hidden;
            display:inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
            min-width: var(--number-background-width);
            padding: 0 .3rem;
            border-radius: 0.2em;
          }
        }
      }
    }
  }
  .templates__more {
    bottom: 30px;
  }
}

.hide_block {
  opacity: 0;
}
