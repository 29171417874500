.color_picker {
  background-color: white;
  border-radius: 0.390625vw;
  width: 14.921875000000002vw;
  padding: 0.546875vw 0.703125vw;
  box-shadow: 0 0.078125vw 0.3125vw 0 rgba(0, 0, 0, 0.25);
  &__colors {
    display: flex;
    flex-wrap: wrap;
    gap: calc(0.234375vw * 2);
    &__color {
      width: 1.7187500000000002vw;
      height: 1.7187500000000002vw;
      border-radius: 0.15625vw;
      cursor: pointer;
    }
    &__custom {
      display: flex;
      background-color: white;
      width: calc( 7.578124999999999vw + 0.78vw);
      height: 1.7187500000000002vw;
      overflow: hidden;
      border-radius: 0.15625vw;
      box-shadow: 0 0 0.078125vw 0 rgba(0, 0, 0, 0.25);
      &__icon {
        width: 1.875vw;
        height: 100%;
        background-color: #F3EDED;
        color: #B8B8B8;
        font-size: 1.015625vw;
        font-family: "Inter", sans-serif;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &__input {
        & input {
          width: calc(5.703125vw - 0.546875vw - 1.41875vw);
          border: none;
          height: 100%;
          font-size: 0.78125vw;
          color: #838383;
          padding: 0 0.546875vw;
        }
      }
      &__picker {
        & input {
          width: 1.7187500000000002vw;
          border: none;
        }
      }
    }
  }
}
