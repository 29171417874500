.admin {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &__auth {
    border-radius: calc(min(5px, 0.390625vw));
    background: #FFF;
    box-shadow: 0 calc(min(1px, 0.078125vw)) 0 0 rgba(0, 0, 0, 0.25);
    padding: calc(min(25px, 1.953125vw));
    position: absolute;
    &__fields {
      display: flex;
      flex-direction: column;
      &__field {
        background: #FFF;
        box-shadow: 0 0 calc(min(2px, 0.15625vw)) 0 rgba(0, 0, 0, 0.25);
        padding: calc(min(10px, 0.78125vw));
        border: none;
        flex-shrink: 0;
        margin: calc(min(10px, 0.78125vw)) 0;
      }
    }
    &__error {
      color: #FF0000;
      font-family: Inter, sans-serif;
      font-size: calc(min(14px, 1.09375vw));
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: calc(min(15px, 1.171875vw));
      text-align: start;
    }
    &__footer {
      margin-top: calc(min(15px, 1.171875vw));
    }
  }
  &__list {
    border-radius: calc(min(5px, 0.390625vw));
    background: #FFF;
    box-shadow: 0 calc(min(1px, 0.078125vw)) 0 0 rgba(0, 0, 0, 0.25);
    padding: calc(min(35px, 2.734375vw));
    &__head {
      display: flex;
      justify-content: space-between;
      &__price {
        display: flex;
        align-items: center;
        &__text {
          color: #000;
          font-family: Inter, sans-serif;
          font-size: calc(min(14px, 1.09375vw));
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        &__field {
          margin-left: calc(min(17px, 1.328125vw));
          margin-right: calc(min(6px, 0.46875vw));
          max-width: calc(min(88px, 6.875000000000001vw));
          background: #FFF;
          box-shadow: 0 0 calc(min(2px, 0.15625vw)) 0 rgba(0, 0, 0, 0.25);
          padding: calc(min(10px, 0.78125vw));
          border: none;
          flex-shrink: 0;
        }
      }
    }
    &__body {
      &__table {
        margin: calc(min(30px, 2.34375vw)) 0;
        width: 100%;
        max-height: 406px;
        background: #F8F8F8;
        border-radius: calc(min(5px, 0.390625vw));
        &__value {
          color: #585858;
          font-family: Inter, sans-serif;
          font-size: calc(min(14px, 1.09375vw));
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          &__field {
            background: #FFF;
            box-shadow: 0 0 calc(min(2px, 0.15625vw)) 0 rgba(0, 0, 0, 0.25);
            padding: calc(min(5px, 0.390625vw));
            border: none;
            flex-shrink: 0;
          }
        }
        & th {
          color: #000;
          font-family: Inter, sans-serif;
          font-size: calc(min(14px, 1.09375vw));
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      &__table, td, th {
        border: calc(min(1px, 0.078125vw)) solid rgba(131, 131, 131, 0.20);
        border-collapse: collapse;
        padding: calc(min(13px, 1.015625vw));
      }
    }
    &__footer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
}
