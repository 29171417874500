.cashier-card {
  max-width: calc((100% - (50px * 3)) / 3);
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 11px 16px 55px;
  border: 1px solid rgba(0, 0, 0, 0.02);
  position: relative;
  @media screen and (max-width: 992px){
    max-width: calc((100% - (46px * 2)) / 2)
  }
  @media screen and (max-width: 992px){
    max-width: 100%
  }
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 6px;
    &__title {
      color: #518C70;
      font-size: 16px;
      font-weight: 400;
    }
    &__number {
      color: #518C70;
      font-size: 16px;
      font-weight: 800;
    }
  }
  &__line {
    margin: 0;
    background: rgba(0, 0, 0, 0.10);
    height: 1px;
    width: 100%;
    border: none;
  }
  &__list {
    &__item {
      display: flex;
      justify-content: space-between;
      padding-top: 9px;
      &__name {
        color: #838383;
        font-size: 14px;
        font-weight: 400;
      }
      &__count {
        color: #838383;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
  &__btn {
    position: absolute;
    bottom: 16px;
    width: calc(100% - 32px);
    border-radius: 4px;
    color: white;
    background: #518C70;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      background: #3D6E54;
    }
    &-ready {
      background: #E8DE83;
      color: #A3930A;
      &:hover {
        background: #D9C94F;
      }
    }
  }
}