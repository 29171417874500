.templates {
  width: 100vw;
  height: 100vh;
  background: white;
  overflow: hidden;

  &-min {
    width: 100% !important;
    height: 100% !important;
  }

  &__more {
    position: absolute;
    bottom: 0px;
    font-weight: bold;
    color: #606060;
    font-size: 2.5vw;
  }

  &__block {
    padding: 2.6875vh 3.9843749999999996vw;
    display: flex;
    background: white;
    position: relative;
    width: calc(100vw - 7.96875vw);
    height: calc(100vh - 7.375vh);
    overflow: hidden;

    &__active {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, calc(100% + calc(min(5px, 0.390625vw))));
      display: flex;
      align-items: center;
      color: #518C70;
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: calc(min(12px, 0.9375vw));
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      & img {
        margin-left: calc(min(4px, 0.3125vw));
      }
    }

    &-two {
      padding: 0;
      width: calc(min(203px, 15.859375vw));

      .templates__body__blocks__block {
        &__line {
          margin: 0;
        }

        &__item {
          &__title {
            background: #6B0B18;
            border-radius: 0;
            justify-content: flex-start;
            padding: 0 calc(min(12px, 0.9375vw));
            width: calc(100% - calc(min(24px, 1.875vw)));

            &-done {
              background: #183E30;
              margin-left: calc(min(-1px, -0.078125vw));
              width: calc(100% - calc(min(23px, 1.7968749999999998vw)));
            }
          }

          &__numbers {
            padding: 0 calc(min(12px, 0.9375vw));
            height: calc(100vh - 8.331730769230769vh - 6.640625vw);

            &__number {
              background: none;
              color: #6B0B18;

              &-done {
                color: #183E30;
              }
            }
          }
        }
      }
    }

    &-three {
      padding: 0;
      width: calc(min(203px, 15.859375vw));

      .templates__body__blocks__block {
        &__line {
          margin: 0;
        }

        &__item {
          &__title {
            background: #000;
            border-radius: 0;
            padding: 0 calc(min(12px, 0.9375vw));
            width: calc(100% - calc(min(24px, 1.875vw)));

            & img {
              width: calc(min(13px, 1.015625vw));
              margin-left: calc(min(5px, 0.390625vw));
            }

            &-done {
              background: #000;
              margin-left: calc(min(-1px, -0.078125vw));
              width: calc(100% - calc(min(23px, 1.7968749999999998vw)));
            }
          }

          &__numbers {
            padding: 0 calc(min(12px, 0.9375vw));
            height: calc(100vh - 8.331730769230769vh - 6.640625vw);

            &__number {
              background: none;
              color: #757575;

              &-done {
                color: #518C70;
              }
            }
          }
        }
      }
    }

    &__item {
      cursor: pointer;

      &__title {
        border-radius: 0.78125vw;
        background: #422757;
        box-shadow: 0px 0.3125vw 0.3125vw 0px rgba(0, 0, 0, 0.25);
        color: #FFF;
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 3.125vw;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        width: 40.9375vw;
        height: 6.484375000000001vw;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        & span {
          overflow:hidden;
          display:inline-block;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &-done {
          background-color: #518C70;
        }
      }

      &__numbers {
        margin-top: 4.331730769230769vh;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        padding-bottom: 2vw;
        height: calc(100vh - 19.331730769230769vh - 2.640625vw);

        &__number {
          color: #422757;
          font-family: Inter, sans-serif;
          font-size: 5.46875vw;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
          border-radius: 0.78125vw;
          background: #D3D3D3;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: calc(21.875vw + 0.78125vw);
          padding-left: 0.78125vw;
          margin-bottom: 0.9375vw;
          margin-right: 0.9375vw;

          &-done {
            background-color: #DAEEE4;
            color: #518C70;
          }
        }
      }
    }

    &__line {
      height: 100%;
      width: 0.3125vw;
      background-color: #E1DBDB;;
      margin: 0 4.978125vw;
    }
  }

  &__black {
    &__block {
      width: 100vw;
      max-height: 100vh;
      height: 100vh;
      overflow: hidden;
      &__head {
        width: 100%;
        background: #000;
        box-shadow: 0 0.3125vw 0.3125vw 0 rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: space-around;
        padding: 2.8846153846153846vh 0;
        &__title {
          color: #FFF;
          text-align: center;
          font-family: Inter, sans-serif;
          font-size: 3.125vw;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
          display: flex;
          align-items: center;
          overflow: hidden;
          width: 50%;
          padding: 0 2.5vw;
          & span {
            overflow:hidden;
            display:inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          & img {
            width: 3.515625vw;
            margin-left: 0.78125vw;
          }
        }
      }
      &__main {
        display: flex;
        height: 100%;
        &__numbers {
          width: 100%;
          height: calc(100vh - 8.331730769230769vh - 6.640625vw);
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          margin: 2.331730769230769vh 2.734375vw 0 7.331730769230769vh;
          &__number {
            color: #757575;
            font-family: Inter, sans-serif;
            font-size: 4.296875vw;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            width: calc(21.875vw + 0.78125vw);
            margin-right: 0.9375vw;
            &-done {
              color: #518C70;
            }
          }
        }
        &__line {
          height: 100%;
          width: 0.3125vw;
          background: #E1DBDB;
        }
      }
    }
  }

  &__full {
    &__block {
      width: 100vw;
      max-height: 100vh;
      height: 100vh;
      overflow: hidden;
      &__head {
        width: 100%;
        box-shadow: 0 0.3125vw 0.3125vw 0 rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: space-around;
        &__title {
          background: #6B0B18;
          color: #FFF;
          text-align: center;
          font-family: Inter, sans-serif;
          font-size: 3.125vw;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
          display: flex;
          align-items: center;
          width: 50%;
          padding: 2.8846153846153846vh 2.5vw;
          overflow:hidden;
          & span {
            overflow:hidden;
            display:inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          & img {
            width: 3.515625vw;
            margin-left: 0.78125vw;
          }
          &:last-child {
            background: #183E30;
          }
        }
      }
      &__main {
        display: flex;
        height: 100%;
        &__numbers {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          margin: 7.331730769230769vh 2.5vw 0;
          height: calc(100vh - 8.331730769230769vh - 6.640625vw);
          &__number {
            color: #6B0B18;
            font-family: Inter, sans-serif;
            font-size: 4.296875vw;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            width: calc(12.5vw + 0.78125vw);
            text-align: left;
            margin-bottom: 0.9375vw;
            margin-right: 0.9375vw;
            &-done {
              color: #183E30;
            }
          }
        }
        &__line {
          height: 100%;
          width: 0.3125vw;
          background: #E1DBDB;
        }
      }
    }
  }
}

