.contacts {
  padding-top: calc(min(19px, 1.484375vw));
  padding-left: calc(min(21px, 1.640625vw));
  width: 100%;
  &__tile {
    color: var(--Main, #518C70);
    font-family: Inter, sans-serif;
    font-size: calc(min(14px, 1.09375vw));
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
  }
  &__body {
    margin-top: calc(min(22px, 1.7187500000000002vw));
    &__item {
      padding: calc(min(8px, 0.625vw)) calc(min(14px, 1.09375vw));
      border-radius: calc(min(4px, 0.3125vw));
      background: var(--Text-light, #FFF);
      width: calc(min(290px, 22.65625vw));
      margin-bottom: calc(min(13px, 1.015625vw));
      display: flex;
      align-items: center;
      &__icon {
        padding-right: calc(min(11px, 0.8593750000000001vw));
        border-right: 1px solid rgba(0, 0, 0, 0.10);
        & svg {

          height: calc(min(28px, 2.1875vw));
          width: calc(min(28px, 2.1875vw));
        }
      }
      &__text {
        margin-left: calc(min(15px, 1.171875vw));
        font-family: Inter, sans-serif;
        font-size: calc(min(14px, 1.09375vw));
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  &__background {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    &__text {
      color: var(--Text-Gray, #838383);
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: calc(min(14px, 1.09375vw));
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: calc(min(14px, 1.5625vw));
    }
    &__icon {
      & svg {
        height: calc(min(158px, 12.34375vw));
        width: calc(min(158px, 12.34375vw));
      }
    }
  }
}