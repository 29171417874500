.base-select {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
    border-radius: 2px;
    background-color: #fff;
    font-size: 16px;
    line-height: 1.5;
    color: #838383;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
    cursor: pointer;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &__main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        height: 40px;
      &__value {
        font-family: Inter, sans-serif;
        font-size: calc(min(12px, 0.9375vw));
        font-style: normal;
        font-weight: 400;
      }
        &__title {
            color: #518C70;
            font-size: 16px;
            font-weight: 400;
        }
        &__number {
            color: #518C70;
            font-size: 16px;
            font-weight: 800;
        }
    }
  &__options {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    min-width: 100%;
    margin: 2px 0 0;
    padding: 1px 0;
    list-style: none;
    font-size: 16px;
    text-align: left;
    background-color: #fff;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    &__option {
      display: block;
      width: calc(100% - 20px);
      padding: 3px 10px;
      clear: both;
      font-weight: 400;
      color: #838383;
      text-align: inherit;
      white-space: nowrap;
      background-color: transparent;
      border-bottom: 1px solid rgba(0,0,0,.1);
      &:hover, &:focus {
        color: #16181b;
        text-decoration: none;
        background-color: #f8f9fa;
      }
      &:last-child {
        border-bottom: none;
      }
      &__selected {
        color: #16181b;
        text-decoration: none;
        background-color: #f8f9fa;
      }
    }
  }
}
