.configuration {
  padding: calc(min(27px, 2.109375vw)) calc(min(23px, 1.7968749999999998vw)) calc(min(18px, 1.40625vw));
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__body {
    &__settings {
      display: flex;
      width: 100%;
      &__setting {
        &:first-child {
          margin-right: calc(min(27px, 2.109375vw));
        }
        &__label {
          color: #585858;
          font-family: Inter, sans-serif;
          font-size: calc(min(14px, 1.09375vw));
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        &__field {
          width: calc(min(279px, 21.796875vw));
          margin-top: calc(min(9px, 0.703125vw));
        }
      }
    }
    &__time {
      display: flex;
      align-items: center;
      margin-top: calc(min(14px, 1.09375vw));
      &__label {
        color: #585858;
        font-family: Inter, sans-serif;
        font-size: calc(min(14px, 1.09375vw));
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        max-width: calc(min(487px, 38.046875vw));
      }
      &__field {
        width: calc(min(69px, 5.390625vw));
        margin: calc(min(9px, 0.703125vw));
      }
      &__additional {
        color: #838383;
        font-family: Inter, sans-serif;
        font-size: calc(min(12px, 0.9375vw));
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    &__font-size {
      margin-top: calc(min(10px, 0.78125vw));
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      &__title {
        font-size: calc(min(14px, 1.019375vw));
        color: #585858;
      }
      &__field {
        width: calc(min(279px, 21.796875vw));
      }
    }
    &__services {
      margin-top: calc(min(26px, 2.03125vw));
      &__title {
        font-size: calc(min(14px, 1.09375vw));
        color: #585858;
      }
      &__list {
        display: flex;
        margin-top: calc(min(14px, 1.09375vw));
        &__item {
          display: flex;
          align-items: center;
          &__disabled {
            opacity: .5;
          }
          &:not(:last-child) {
            margin-right: calc(min(20px, 1.5625vw));
          }
          & label {
            font-size: 12px;
            color: #838383;
            display: block;
            margin-top: calc(min(4px, 0.3125vw));
            margin-left: calc(min(7px, 0.546875vw));
            cursor: pointer;
          }
          & input {
            cursor: pointer;
            margin-right: calc(min(14px, 1.09375vw));
            width: 1px;
          }
        }
      }
    }
    &__line {
      height: 1px;
      width: 100%;
      background: rgba(0, 0, 0, 0.10);
      margin-top: calc(min(20px, 1.5625vw));
    }
    &__title {
      margin-top: calc(min(13px, 1.015625vw));
      color: var(--Main, #518C70);
      font-family: Inter, sans-serif;
      font-size: calc(min(14px, 1.09375vw));
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    &__list {
      padding-left: calc(min(14px, 1.09375vw));
      & li {
        margin-bottom: calc(min(10px, 0.78125vw));
        color: var(--Dark-gray, #585858);
        font-family: Inter, sans-serif;
        font-size: calc(min(14px, 1.09375vw));
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        & a {
          color: var(--Secondary, #422757);
          text-decoration: none;
        }
      }
    }
    &__description {
      color: var(--Dark-gray, #585858);
      font-family: Inter, sans-serif;
      font-size: calc(min(12px, 0.9375vw));
      font-style: normal;
      font-weight: 400;
      & a {
        color: var(--Dark-gray, #585858);
        font-family: Inter, sans-serif;
        font-size: calc(min(12px, 0.9375vw));
        font-style: normal;
        font-weight: bold;
        text-decoration: none;
        margin-top: calc(min(6px, 0.46875vw));
        display: block;
      }
    }
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    &__btn {

    }
  }
  input[type='radio']:after {
    width: calc(min(7px, 0.546875vw));
    height: calc(min(7px, 0.546875vw));
    border-radius: calc(min(15px, 1.171875vw));
    top: calc(min(-2px, -0.15625vw));
    left: calc(min(-1px, -0.078125vw));
    position: relative;
    background-color: white;
    content: '';
    display: inline-block;
    visibility: visible;
    border: calc(min(5px, 0.390625vw)) solid white;
    box-shadow: 0 calc(min(2px, 0.15625vw)) calc(min(1px, 0.078125vw)) 0 rgba(0, 0, 0, 0.25);
  }

  input[type='radio']:checked:after {
    width: calc(min(7px, 0.546875vw));
    height: calc(min(7px, 0.546875vw));
    border-radius: calc(min(15px, 1.171875vw));
    top: calc(min(-2px, -0.15625vw));
    left: calc(min(-1px, -0.078125vw));
    position: relative;
    background-color: #7D7E7D;
    content: '';
    display: inline-block;
    visibility: visible;
    border: calc(min(5px, 0.390625vw)) solid white;
  }
}
