.base-toggle {
  height: calc(min(14px, 1.09375vw));
  input[type=checkbox]{
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    margin-top: calc(min(-18px, -1.40625vw));
    cursor: pointer;
    text-indent: -9999px;
    width: calc(min(28px, 2.1875vw));
    height: calc(min(14px, 1.09375vw));
    background: #E7E7E7;
    display: block;
    border-radius: calc(min(100px, 7.8125vw));
    position: relative;
  }

  label:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: calc(min(14px, 1.09375vw));
    height: calc(min(14px, 1.09375vw));
    transition: 0.3s;

    border-radius: calc(min(30px, 2.34375vw));
    background: white;
    box-shadow: 0 calc(min(1px, 0.078125vw)) calc(min(1px, 0.078125vw)) 0 rgba(0, 0, 0, 0.25);
  }

  input:checked + label {
    background: rgba(81, 140, 112, 0.20);
  }

  input:checked + label:after {
    left: 100%;
    background: #518C70;
    transform: translateX(-100%);
  }

  label:active:after {
    width: calc(min(20px, 1.5625vw));
  }

  // centering
  body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
}