.information {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  &__body {
    padding: calc(min(37px, 2.890625vw)) calc(min(17px, 1.328125vw));
    &__head {
      margin-bottom: calc(min(38px, 2.96875vw));
      &__item {
        display: flex;
        align-items: center;
        &__name {
          color: #585858;
          font-family: Inter, sans-serif;
          font-size: calc(min(14px, 1.09375vw));
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-right: calc(min(7px, 0.546875vw));
        }
        &__value {
          color: #838383;
          font-family: Inter, sans-serif;
          font-size: calc(min(12px, 0.9375vw));
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-decoration: none;
        }
      }
    }
    &__title {
      display: block;
      text-align: left;
      color: #585858;
      font-family: Inter, sans-serif;
      font-size: calc(min(14px, 1.09375vw));
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &__table {
      margin-top: calc(min(12px, 0.9375vw));
      text-align: left;
      width: calc(min(602px, 47.03125vw));
      & table {
        width: 100%;
        border-collapse: collapse;
        & th {
          color: #585858;
          font-family: Inter, sans-serif;
          font-size: calc(min(14px, 1.09375vw));
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding: calc(min(13px, 1.015625vw));
          border-top: calc(min(1px, 0.078125vw)) solid #E7E7E7;
          border-bottom: calc(min(1px, 0.078125vw)) solid #E7E7E7;
        }
        & td {
          color: #838383;
          font-family: Inter, sans-serif;
          font-size: calc(min(12px, 0.9375vw));
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding: calc(min(11px, 0.8593750000000001vw));
        }
      }
    }
  }
  &__footer {
    margin-top: auto;
    &__notification {
      display: flex;
      justify-content: space-between;
      padding: calc(min(19px, 1.484375vw)) calc(min(27px, 2.109375vw));
      border-radius: 0px 0px calc(min(5px, 0.390625vw)) 0px;
      background: #DBE6DD;
      &__message {
        color: #518C70;
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: calc(min(12px, 0.9375vw));
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      &__extend {
        color: #585858;
        font-family: Inter, sans-serif;
        font-size: calc(min(12px, 0.9375vw));
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        & a {
          color: #585858;
          font-family: Inter, sans-serif;
          font-size: calc(min(14px, 1.09375vw));
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }
}