@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.templates {
  --initial-font-size: 3.90625vw;

  --in-progress-background: #FFFFFF;
  --in-progress-head-background: #6B0B18;
  --in-progress-head-color: #FFFFFF;
  --in-progress-number-color: #FFFFFF;
  --in-progress-more-color: #FFFFFF;

  --done-background: #CAEFF8;
  --done-head-background: #183E30;
  --done-head-color: #2F50E5;
  --done-number-color: #2F50E5;
  --done-more-color: #2F50E5;

  //--head-font-size: .16em;
  --head-font-size: 1.08em;

  --number-font-size: 5.015625vw;
  //--number-font-size: .93vw;

  --count-columns: 3;

  --more-font-size: .34em;

  &__text--active {
    text-decoration: underline;
  }

  &__full_head {
    position: relative;
    font-family: "Inter", sans-serif;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    text-align: start;
    font-size: var(--initial-font-size);

    &__line {
      height: 100%;
      width: calc(min(2px, 0.15625vw));
      background-color: rgba(0, 0, 0, .1);
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      z-index: 8;
    }

    &__in_progress {
      background: var(--in-progress-background);
      position: relative;

      &__bg {
        background-color: var(--in-progress-head-background);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .templates__full_head__block__head {
        color: var(--in-progress-head-color);
        font-weight: bold;
        position: absolute;
        width: 100%;
      }

      .templates__full_head__block__numbers {
        color: var(--in-progress-number-color);
      }

      .templates__more {
        color: var(--in-progress-more-color);
        font-size: var(--more-font-size);
      }
    }

    &__done {
      background: var(--done-background);

      .templates__full_head__block__head {
        color: var(--done-head-color);
        font-weight: 800;
      }

      .templates__full_head__block__numbers {
        color: var(--done-number-color);
      }

      .templates__more {
        color: var(--done-more-color);
        font-size: var(--more-font-size);
      }

    }

    &__block {
      width: 50%;
      height: calc(100% - 0.78125% - 2.5%);
      padding: 0 2.5% 2.5%;
      position: relative;

      &__head {
        position: absolute;
        font-size: var(--head-font-size);
        width: 100%;
        left: 0;

        &__bg {
          background-color: var(--done-head-background);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        & span {
          z-index: 10;
          position: relative;
          margin-left: 2.5%;
        }
      }

      &__numbers {
        margin-top: 12.7187500000000002%;
        font-size: var(--number-font-size);
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        height: calc(91% - 30px);
        overflow: hidden;

        &__number {
          width: calc(100% / var(--count-columns));
          font-weight: bold;
        }
      }
    }
  }
}

.hide_block {
  opacity: 0;
}
