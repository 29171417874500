.tariffs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  &__body {
    padding: calc(min(20px, 1.5625vw));
    &__title {
      color: #518C70;
      font-family: Inter, sans-serif;
      font-size: calc(min(14px, 1.09375vw));
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: calc(min(23px, 1.7968749999999998vw));
    }
    &__tariffs {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: calc(min(24px, 1.875vw));
      &__item {
        background-color: white;
        border-radius: calc(min(5px, 0.390625vw));
        padding: calc(min(12px, 0.9375vw));
        box-shadow: 0 0 calc(min(2px, 0.15625vw)) rgba(0, 0, 0, 0.10);
        &-active {
          box-shadow: 0 0 calc(min(4px, 0.3125vw)) #518C70;
        }
        &__title {
          display: flex;
          justify-content: center;
          align-items: center;
          &__img {
            width: calc(min(24px, 1.875vw));
          }
          &__text {
            margin-left: calc(min(5px, 0.390625vw));
            color: #585858;
            font-family: Inter, sans-serif;
            font-size: calc(min(13px, 1.015625vw));
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        &__body {
          display: flex;
          justify-content: center;
          margin: calc(min(20px, 1.5625vw)) 0 calc(min(25px, 1.953125vw));
          &__img {
            width: calc(min(23px, 1.7968749999999998vw));
          }
          &__text {
            margin-left: calc(min(23px, 1.7968749999999998vw));
            color: #585858;
            font-family: Inter, sans-serif;
            font-size: calc(min(11px, 0.8593750000000001vw));
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            height: max-content;
          }
        }
        &__footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          &__price {
            &__summ {
              color: #518C70;
              font-family: Inter, sans-serif;
              font-size: calc(min(21px, 1.640625vw));
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
            &__currency {
              margin-top: calc(min(-4px, -0.3125vw));;
              color: #518C70;
              font-family: Inter, sans-serif;
              font-size: calc(min(10px, 0.78125vw));
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
          &__tariff {
            &__text {
              color: #518C70;
              font-family: Inter, sans-serif;
              font-size: calc(min(11px, 0.8593750000000001vw));
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
            &__btn {
              width: calc(min(94px, 7.34375vw));
              height: calc(min(28px, 2.1875vw));
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: calc(min(3px, 0.234375vw));
              border: calc(min(1px, 0.078125vw)) solid #518C70;
              font-family: Inter, sans-serif;
              font-size: calc(min(9px, 0.703125vw));
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              cursor: pointer;
              color: #518C70;
              transition: all .2s;
              &:hover {
                background: #518C70;
                color: white;
              }
            }
          }
        }
      }
    }
    &__info {
      display: flex;
      margin-top: calc(min(24px, 1.875vw));
      &__item {
        display: flex;
        align-items: center;
        margin-left: calc(min(30px, 2.34375vw));
        &:first-child {
          margin-left: 0;
        }
        &__img {
          width: calc(min(24px, 1.875vw));
        }
        &__text {
          margin-left: calc(min(10px, 0.78125vw));
          color: #585858;
          font-family: Inter, sans-serif;
          font-size: calc(min(12px, 0.9375vw));
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
  &__footer {
    text-align: left;
    color: #518C70;
    font-family: Inter, sans-serif;
    font-size: calc(min(12px, 0.9375vw));
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 0 0 calc(min(5px, 0.390625vw)) 0;
    background: #DBE6DD;
    padding: calc(min(13px, 1.015625vw)) calc(min(30px, 2.34375vw));
    width: calc(min(580px, 45.3125vw));
  }
  &__modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100%;
      &__modal {
        background-color: white;
        width: calc(min(323px, 25.234374999999996vw));
        height: calc(min(261px, 20.390625vw));
        padding: calc(min(15px, 1.171875vw)) calc(min(10px, 0.78125vw));
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: calc(min(3px, 0.234375vw));
        &__title {
          color: #585858;
          font-family: Inter, sans-serif;
          font-size: calc(min(12px, 0.9375vw));
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-align: start;
        }
        &__body {
          margin: 0 auto;
          width: calc(min(187px, 14.609375vw));
          text-align: start;
          .tariffs__body__tariffs__item__footer {
            justify-content: center;
          }
        }
        &__footer {
           display: flex;
          justify-content: flex-end;
          &__cancel {
            width: calc(min(106px, 8.28125vw));
            height: calc(min(28px, 2.1875vw));
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: calc(min(3px, 0.234375vw));
            border: calc(min(1px, 0.078125vw)) solid #518C70;
            font-family: Inter, sans-serif;
            font-size: calc(min(9px, 0.703125vw));
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            cursor: pointer;
            color: #518C70;
            transition: all .2s;
            &:hover {
              background: #518C70;
              color: white;
            }
          }
          &__approve {
            margin-left: calc(min(7px, 0.546875vw));
            width: calc(min(106px, 8.28125vw));
            height: calc(min(28px, 2.1875vw));
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: calc(min(3px, 0.234375vw));
            border: 1px solid rgba(81, 140, 112, 0);
            background: #518C70;
            font-family: Inter, sans-serif;
            font-size: calc(min(9px, 0.703125vw));
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            cursor: pointer;
            color: white;
            transition: all .2s;
            &:hover {
              background: #3E6A4F;
            }
            &-disable {
              background: #D9D9D9;
              color: #585858;
              cursor: not-allowed;
              &:hover {
                  background: #D9D9D9;
              }
            }
          }
        }
      }
    }
  }
}