.accordion {
  text-align: left;
  width: calc(min(579px, 45.234375vw));
  border-radius: calc(min(4px, 0.3125vw));
  background: var(--Text-light, #FFF);
  padding: 0 calc(min(11px, 0.8593750000000001vw));
  margin-top: calc(min(10px, 0.78125vw));
  &__head {
    padding: calc(min(10px, 0.78125vw)) 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    &__title {
      color: #585858;
      font-family: Inter, sans-serif;
      font-size: calc(min(13px, 1.015625vw));
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &__arrow {
      transition: all 0.3s ease-in-out;
      & img {
        width: calc(min(12px, 0.9375vw));
      }
      &-active {
        transform: rotate(90deg);
      }
    }
  }
  &__body {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease-in-out, padding-bottom 0.3s ease-in-out;
    &__text {
      border-top: 1px solid rgba(0, 0, 0, 0.10);
      padding-top: calc(min(11px, 0.8593750000000001vw));
      color: #838383;
      font-family: Inter, sans-serif;
      font-size: calc(min(12px, 0.9375vw));
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      & a {
        color: #518C70;
      }
    }
    &-show {
      max-height: calc(min(500px, 39.0625vw));
      padding-bottom: calc(min(17px, 1.328125vw));
    }
  }
}