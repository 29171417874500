.base-button {
  border-radius: calc(min(5px, 2.65625vw));
  background: var(--Main, #518C70);
  padding: calc(min(12px, 0.9375vw));
  font-family: Inter, sans-serif;
  color: #FFF;
  font-size: calc(min(12px, 0.9375vw));
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
  cursor: pointer;
  &:disabled {
    cursor: auto;
    background: #E7E7E7;
  }
  &__underlined {
    background: none;
    color: var(--Main, #518C70);
    border: calc(min(1px, 0.078125vw)) solid var(--Main, #518C70);
    &:disabled {
      background: none;
      color: #E7E7E7;
      border: calc(min(1px, 0.078125vw)) solid #E7E7E7;
    }
  }
}