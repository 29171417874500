.policy-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  z-index: 15;
  overflow-y: auto;
  &__content {
    background: #FFF;
    box-shadow: 0 calc(min(4px, 0.3125vw)) calc(min(4px, 0.3125vw)) 0 rgba(0, 0, 0, 0.25);
    padding: calc(min(44px, 3.4375000000000004vw)) calc(min(30px, 2.34375vw)) calc(min(10px, 0.78125vw));
    max-width: calc(min(540px, 42.1875vw));
    height: max-content;
    margin: calc(min(20px, 1.5625vw)) 0;
    &__title {
      color: #000;
      text-align: start;
      font-family: Inter, sans-serif;
      font-size: calc(min(12px, 0.9375vw));
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &__list {
      margin-top: calc(min(20px, 1.5625vw));
      color: var(--Dark-gray, #585858);
      text-align: start;
      font-family: Inter, sans-serif;
      font-size: calc(min(12px, 0.9375vw));
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-left: 0;
    }
    &__checkbox {
      margin-top: calc(min(20px, 1.5625vw));
      display: flex;
      color: var(--Text-dark, #000);
      font-family: Inter, sans-serif;
      font-size: calc(min(11px, 0.8593750000000001vw));
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      cursor: pointer;
      & label {
        margin-left: calc(min(10px, 0.78125vw));
        cursor: pointer;
        & span {
          display: block;
        }
      }
    }
    &__btn {
      display: flex;
      justify-content: flex-end;
    }
    &__footer {
      color: var(--Text-Gray, #838383);
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: calc(min(7px, 0.546875vw));
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: calc(min(10px, 0.78125vw));
    }
  }
}