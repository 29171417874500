.list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  text-align: left;
  @media screen and (max-width: 700px){
    justify-content: flex-start;
    padding-top: calc(min(57px, 14.50381679389313vw));
  }
  &__logo {
    &__image {
      width: calc(min(220px, 17.1875vw));
      @media screen and (max-width: 700px){
        width: calc(min(211px, 53.68956743002544vw));
      }
    }
    &__text{
      color: #518C70;
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: calc(min(14px, 1.09375vw));
      @media screen and (max-width: 700px){
        font-size: calc(min(20px, 1.5625vw));
      }
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  &__body {
    margin-top: calc(min(28px, 2.1875vw));
    @media screen and (max-width: 700px){
      margin-top: calc(min(91px, 23.155216284987276vw));
    }
    &__block {
      position: relative;
      width: max-content;
      border-radius: calc(min(5px, 0.390625vw));
      background: #FFF;
      box-shadow: 0px calc(min(1px, 0.078125vw)) 0px 0px rgba(0, 0, 0, 0.25);
      padding:  calc(min(24px, 1.875vw)) calc(min(67px, 5.234375vw)) calc(min(30px, 2.34375vw));
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      @media screen and (max-width: 700px){
        padding:  calc(min(18px, 4.580152671755725vw)) calc(min(40px, 10.178117048346055vw)) calc(min(13px, 3.307888040712468vw)) calc(min(44px, 11.195928753180661vw));
      }
      &__language {
        position: absolute;
        top: 0;
        left: 100%;
        transform: translate(-100%, 0);
        margin-top: -30px;
      }
      &__title {
        color: #518C70;
        font-family: Inter, sans-serif;
        font-size: calc(min(22px, 1.7187500000000002vw));
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: calc(min(39px, 3.046875vw));
        @media screen and (max-width: 700px){
            font-size: calc(min(17px, 4.325699745547073vw));
        }
      }
      &__field {
        width: calc(min(425px, 33.203125vw));
        @media screen and (max-width: 700px){
          width: calc(min(320px, 67.68447837150127vw));
        }
        &-mt {
          margin-top: calc(min(20px, 1.5625vw));
        }
        &__label {
          color: #838383;
          font-family: Inter, sans-serif;
          font-size: calc(min(14px, 1.09375vw));
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          @media screen and (max-width: 700px){
            font-size: calc(min(12px, 3.0534351145038165vw));
          }
        }
        &__input {
          margin-top: calc(min(6px, 0.46875vw));
          margin-bottom: calc(min(2px, 0.15625vw));
          @media screen and (max-width: 700px){
            margin-top: calc(min(5px, 1.2690355329949238vw));
            & input {
              font-size: calc(min(12px, 3.0534351145038165vw));
              height: calc(min(27px, 6.870229007633588vw));
            }
          }
        }
        &__error {
          color: #893F3F;
          font-family: Inter, sans-serif;
          font-size: calc(min(11px, 0.8593750000000001vw));
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
          &-show {
            opacity: 1;
          }
        }
      }
      &__btn {
        margin-top: calc(min(17px, 1.328125vw));
        @media screen and (max-width: 700px){
          & span button {
            font-size: calc(min(12px, 3.0534351145038165vw));
            height: calc(min(33px, 8.396946564885496vw));
            width: calc(min(150px, 38.16793893129771vw)) !important;
            border-radius: calc(min(3px, 0.7633587786259541vw));
            margin-top: calc(min(18px, 4.580152671755725vw));
          }
        }
      }
    }
  }
  &__notification {
    margin-top: calc(min(56px, 4.375vw));
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
    &-show {
      opacity: 1;
    }
    &__title {
      color: #518C70;
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: calc(min(19px, 1.484375vw));
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    &__text {
      color: #838383;
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: calc(min(19px, 1.484375vw));
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: calc(min(25px, 1.953125vw));
      margin-bottom: calc(min(80px, 6.25vw));
    }
    &__finish {
      color: #518C70;
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: calc(min(19px, 1.484375vw));
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
