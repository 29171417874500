/* HTML: <div class="loader"></div> */
.connectionStatus {
  width: 5px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: green;
  box-shadow: 0 0 0 0 rgba(0, 128, 0, 0.4);
  animation: connectionStatus 1s infinite;
  &-reed {
    background: red;
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.4);
    @keyframes connectionStatus {
      100% {box-shadow: 0 0 0 8px rgba(255, 0, 0, 0)}
    }
  }
}
@keyframes connectionStatus {
  100% {box-shadow: 0 0 0 8px rgba(0, 128, 0, 0)}
}
