@import url('https://fonts.googleapis.com/css2?family=Francois+One&display=swap');
.home-page {
  font-family: 'Francois One', sans-serif;
  &__head {
    background: linear-gradient(282deg, #518C70 -10.86%, #F2F6F8 94.33%);
    &__content {
      padding-top: calc(min(72px, 5.625vw));
      max-width: calc(min(1066px, 83.28125vw));
      margin: 0 auto;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding-bottom: calc(min(156px, 12.1875vw));
      position: relative;
      @media screen and (max-width: 767px) {
        max-width: 85.24173027989822vw;
        padding-top: 6.870229007633588vw;
        padding-bottom: 5.597964376590331vw;
      }
      &__line {
        width: calc(min(486px, 37.96875vw));
        height: calc(min(1px, 0.078125vw));
        background: #518C70;
        box-shadow: 0 calc(min(2px, 0.15625vw)) calc(min(4px, 0.3125vw)) 0 rgba(0, 0, 0, 0.25);
        @media screen and (max-width: 767px) {
          width: 85.24173027989822vw;
        }
      }
      &__logo {
        margin-top: calc(min(66px, 5.15625vw));
        margin-bottom: calc(min(25px, 1.953125vw));
        color: var(--Main, #518C70);
        text-align: center;
        font-family: Francois One, sans-serif;
        font-size: calc(min(41px, 3.2031249999999996vw));
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        align-items: flex-end;
        & img {
          margin-right: calc(min(10px, 0.78125vw));
          width: calc(min(209px, 16.328125vw));
          margin-bottom: calc(min(10px, 0.78125vw));
          @media screen and (max-width: 767px) {
            margin-right: 2.5445292620865136vw;
            width: 37.913486005089055vw;
          }
        }
        @media screen and (max-width: 767px) {
          margin: 3.816793893129771vw auto 0;
          font-size: 7.888040712468193vw;
        }
      }
      &__title {
        color: #518C70;
        text-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
        font-family: Inter, sans-serif;
        font-size: calc(min(45px, 3.515625vw));
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        width: calc(min(537px, 41.953125vw));
        text-align: left;
        @media screen and (max-width: 767px) {
          margin-top: 5.089058524173027vw;
          font-size: 6.106870229007633vw;
          width: 81.42493638676844vw;
          text-align: center;
          margin-bottom: 72.01017811704835vw;
        }
      }
      &__btn {
        margin-top: calc(min(41px, 3.2031249999999996vw));
        color: #FFF;
        font-family: Inter, sans-serif;
        font-size: calc(min(14px, 1.09375vw));
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border-radius: calc(min(3px, 0.234375vw));
        background: #518C70;
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(min(234px, 18.28125vw));
        height: calc(min(46px, 3.5937499999999996vw));
        cursor: pointer;
        text-decoration: none;
        transition: all 0.2s ease-in-out;
        & img {
          margin-left: calc(min(10px, 0.78125vw));
          width: calc(min(24px, 1.875vw));
          @media screen and (max-width: 767px) {
            width: 5.089058524173027vw;
            margin-left: 2.5445292620865136vw;
          }
        }
        &:hover {
          color: white;
          transform: translate(0, calc(min(-1px, -0.078125vw)));
        }
        @media screen and (max-width: 767px) {
          width: 100%;
          height: auto;
          font-size: 3.0534351145038165vw;
          padding: 2.5445292620865136vw 0;
        }
      }
      &__images {
        &__ipad {
          position: absolute;
          top: calc(min(55px, 4.296875vw));
          width: calc(min(423px, 33.046875vw));
          right: 0;
          @media screen and (max-width: 767px) {
            width: 47.837150127226465vw;
            top: 51.14503816793893vw;
          }
        }
        &__laptop {
          position: absolute;
          top: calc(min(183px, 14.296875vw));
          width: calc(min(498px, 38.90625vw));
          right: calc(min(94px, 7.34375vw));
          @media screen and (max-width: 767px) {
            left: 0;
            width: 61.06870229007634vw;
            top: 64.8854961832061vw;
          }
        }
      }
    }
  }
  &__list {
    background: linear-gradient(294deg, #F2F6F8 -1.94%, #F2F6F8 108.17%);
    &__content {
      padding: calc(min(72px, 5.625vw)) 0;
      max-width: calc(min(1066px, 83.28125vw));
      margin: 0 auto;
      @media screen and (max-width: 767px) {
        max-width: 84.22391857506362vw;
        padding-top: 8.142493638676845vw;
        padding-bottom: 8.396946564885496vw;
      }
      &__items {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 767px) {
          flex-direction: column;
        }
        &__item {
          border-radius: calc(min(12px, 0.9375vw));
          background: #FFF;
          box-shadow: 0 calc(min(2px, 0.15625vw)) calc(min(4px, 0.3125vw)) 0 rgba(81, 140, 112, 0.40);
          width: calc(min(344px, 26.875vw));
          height: calc(min(205px, 16.015625vw));
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          transition: all 0.2s ease-in-out;
          @media screen and (max-width: 767px) {
            width: 84.22391857506362vw;
            height: 52.16284987277354vw;
            margin-bottom: 4.325699745547073vw;
          }
          &:hover {
            transform: translate(0, calc(min(-5px, -0.390625vw)));
          }
          &__img {
            width: calc(min(126px, 9.84375vw));
            @media screen and (max-width: 767px) {
              flex-direction: column;
              height: 26.208651399491096vw;
              width: auto;
            }
          }
          &__text {
            margin-top: calc(min(11px, 0.8593750000000001vw));
            width: calc(min(305px, 23.828125vw));
            color: var(--Dark-gray, #585858);
            text-align: center;
            font-family: Inter, sans-serif;
            font-size: calc(min(20px, 1.5625vw));
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            @media screen and (max-width: 767px) {
              font-size: 5.852417302798982vw;
              width: 100%;
            }
          }
        }
      }
    }
  }
  &__description {
    background: rgba(81, 140, 112, 0.12);
    color: #518C70;
    text-align: center;
    text-shadow: 0 0 calc(min(2px, 0.15625vw)) rgba(0, 0, 0, 0.25);
    font-family: Inter, sans-serif;
    font-size: calc(min(32px, 2.5vw));
    font-style: italic;
    font-weight: 300;
    line-height: normal;
    padding: calc(min(34px, 2.65625vw)) 0;
    @media screen and (max-width: 767px) {
      font-size: 4.8346055979643765vw;
      padding: 6.361323155216285vw 0;
    }
  }
  &__pay {
    background: linear-gradient(294deg, #F2F6F8 -1.94%, #F2F6F8 108.17%);
    padding: calc(min(85px, 6.640625vw)) 0 calc(min(110px, 8.59375vw));
    position: relative;
    @media screen and (max-width: 767px) {
        padding: 11.959287531806616vw 0;
    }
    &__content {
      border-radius: 12px;
      background: #FFF;
      box-shadow: 0 2px 4px 0 rgba(81, 140, 112, 0.40);
      margin: 0 auto;
      width: calc(min(780px, 60.9375vw));
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 767px) {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        width: 84.22391857506362vw;
      }
      &__list {
        width: max-content;
        color: var(--Main, #518C70);
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: calc(min(22px, 1.7187500000000002vw));
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: calc(min(20px, 1.5625vw)) calc(min(60px, 4.6875vw));
        @media screen and (max-width: 767px) {
          padding: 0 0 6.361323155216285vw 0;
          margin: 0;
          font-size: 4.325699745547073vw;
        }
        &__item {
          text-align: left;
          align-items: center;
          margin-bottom: calc(min(13px, 1.015625vw));
          &:last-child {
            margin-bottom: 0;
          }
          @media screen and (max-width: 767px) {
            margin-bottom: 4.071246819338422vw;
            &:last-child {
              margin-bottom: 0;
            }
          }
          & img {
            @media screen and (max-width: 767px) {
              height: 4.325699745547073vw;
            }
            margin-bottom: calc(min(-3px, -0.234375vw));
            height: calc(min(18px, 1.40625vw));
            &:first-child {
              margin-right: calc(min(10px, 0.78125vw));
              margin-left: calc(min(20px, 1.5625vw));
            }
          }
        }
      }
      &__price {
        margin: calc(min(20px, 1.5625vw)) 0;
        padding-right: calc(min(36px, 2.8125vw));
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: calc(min(28px, 2.1875vw));
        border-left: calc(min(1px, 0.078125vw)) solid rgba(81, 140, 112, 0.30);
        @media screen and (max-width: 767px) {
          flex-direction: row;
          justify-content: space-between;
          border-left: none;
          border-bottom: calc(min(1px, 0.078125vw)) solid rgba(81, 140, 112, 0.30);
          padding: 6.361323155216285vw;
          width: calc(100% - 12.7226463104vw);
          margin-top: 0;
          margin-bottom: 6.361323155216285vw;
        }
        & img {
          width: calc(min(68px, 5.3125vw));
          @media screen and (max-width: 767px) {
            width: 13.994910941475828vw;
          }
        }
        &__text {
          color: #518C70;
          text-align: center;
          font-family: Inter, sans-serif;
          font-size: calc(min(22px, 1.7187500000000002vw));
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          @media screen and (max-width: 767px) {
            font-size: 5.597964376590331vw;
          }
          &__sum {
            font-size: calc(min(37px, 2.890625vw));
            @media screen and (max-width: 767px) {
              font-size: 9.414758269720101vw;
            }
          }
        }
      }
    }
    &__icon {
      position: absolute;
      right: 0;
      top: 0;
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }
  &__plans {
    background: rgba(81, 140, 112, 0.12);
    padding-bottom: calc(min(87px, 6.796874999999999vw));
    position: relative;
    &__currency {
      position: absolute;
      top: calc(min(67px, 5.234375vw));
      left: calc(min(367px, 28.671875000000004vw));
      @media screen and (max-width: 767px) {
        top: 7vw;
        left: 8vw;
      }
    }
    &__title {
      color: #518C70;
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: calc(min(32px, 2.5vw));
      font-weight: 400;
      line-height: normal;
      padding: calc(min(34px, 2.65625vw)) 0;
      @media screen and (max-width: 767px) {
        font-size: 4.8346055979643765vw;
        padding: 6.361323155216285vw 0;
      }
    }
    &__content {
      //max-width: calc(min(1066px, 83.28125vw));
      width: calc(min(780px, 60.9375vw));
      margin: 0 auto;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: calc(min(59px, 4.609375vw));
      @media screen and (max-width: 767px) {
        grid-template-columns: 1fr;
        width: 84.22391857506362vw;
      }
      &__item {
        border-radius: calc(min(5px, 0.390625vw));
        background-color: white;
        padding: calc(min(12px, 0.9375vw)) calc(min(13px, 1.015625vw)) calc(min(26px, 2.03125vw));
        width: calc(min(194px, 15.156249999999998vw));
        height: calc(min(95px, 7.421875vw));
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &__cashier {
          height: calc(min(117px, 9.140625vw));
        }
        @media screen and (max-width: 767px) {
          width: calc(100% - 9.66921119593vw);
          height: max-content;
          padding: 4.8346055979643765vw 3.816793893129771vw;
          border-radius: calc(min(20px, 1.5625vw));
        }
        &__head {
          display: flex;
          align-items: center;
          @media screen and (max-width: 767px) {
            margin-bottom: 3.816793893129771vw;
          }
          &__location {
            width: calc(min(28px, 2.1875vw));
            @media screen and (max-width: 767px) {
                width: 3.816793893129771vw;
            }
          }
          &__title {
            text-align: start;
            margin: 0 calc(min(5px, 0.390625vw));
            color: #585858;
            font-family: Inter, sans-serif;
            font-size: calc(min(11px, 0.8593750000000001vw));
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            @media screen and (max-width: 767px) {
              font-size: 3.0534351145038165vw;
            }
          }
          &__price {
            margin-left: auto;
            &__sum {
              color: #518C70;
              font-family: Inter, sans-serif;
              font-size: calc(min(16px, 1.25vw));
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              @media screen and (max-width: 767px) {
                font-size: 5.597964376590331vw;
              }
            }
            &__text {
                color: #518C70;
                font-family: Inter, sans-serif;
                font-size: calc(min(11px, 0.8593750000000001vw));
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              @media screen and (max-width: 767px) {
                font-size: 3.0534351145038165vw;
              }
            }
          }
        }
        &__body {
          display: flex;
          align-items: center;
          @media screen and (max-width: 767px) {
              margin-bottom: 1.2722646310432568vw;
          }
          &__icon {
            margin-right: calc(min(11px, 0.8593750000000001vw));
            width: calc(min(28px, 2.1875vw));
            @media screen and (max-width: 767px) {
              width: 3.816793893129771vw;
              margin-right: 1.1450381679389313vw;
            }
          }
          &__text {
            height: max-content;
            text-align: left;
            color: #585858;
            font-family: Inter, sans-serif;
            font-size: calc(min(12px, 1.015625vw));
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            @media screen and (max-width: 767px) {
              font-size: 3.0534351145038165vw;
            }
          }
        }
      }
    }
  }
  &__footer {
    background: var(--Main, #518C70);
    position: relative;
    &__language {
      position: absolute;
      top: calc(min(17px, 1.328125vw));
      right: calc(min(17px, 1.328125vw));
      @media screen and (max-width: 767px)  {
        right: 3.5623409669211195vw;
        top: 5.089058524173027vw;
      }
    }
    &__currency {
      position: absolute;
      top: calc(min(17px, 1.328125vw));
      right: calc(min(100px, 7.8125vw));
      @media screen and (max-width: 767px)  {
        right: 3.5623409669211195vw;
        top: 13.231552162849871vw;
      }
    }
    &__content {
      padding: calc(min(72px, 5.625vw)) 0;
      max-width: calc(min(1066px, 83.28125vw));
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: 767px) {
        flex-direction: column;
        padding-top: 4.580152671755725vw;
        padding-bottom: 13.740458015267176vw;
        max-width: 84.22391857506362vw;
      }
      &__logo {
        display: flex;
        color: var(--Text-light, #FFF);
        text-align: center;
        font-family: Francois One, sans-serif;
        font-size: calc(min(41px, 3.2031249999999996vw));
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        @media screen and (max-width: 767px) {
          font-size: 7.888040712468193vw;
        }
        & img {
          margin-right: calc(min(10px, 0.78125vw));
          width: calc(min(263px, 20.546875vw));
          margin-bottom: calc(min(10px, 0.78125vw));
          @media screen and (max-width: 767px) {
            width: 35.6234096692112vw;
            margin: 0 2.5445292620865136vw 0 0;
          }
        }
      }
      &__text {
        display: flex;
        @media screen and (max-width: 767px) {
          flex-direction: column;
          margin-top: 7.633587786259542vw;
          width: 100%;
        }
        &__links {
          margin: 0;
          padding-left: 0;
          padding-right: calc(min(104px, 8.125vw));
          @media screen and (max-width: 767px) {
            padding-right: 0;
            width: 100%;
            border-bottom: calc(min(1px, 0.078125vw)) solid rgba(255, 255, 255, 0.20);
          }
          &__link {
            list-style-type: none;
            text-align: left;
            margin-bottom: calc(min(15px, 1.171875vw));
            @media screen and (max-width: 767px) {
              width: 100%;
              margin-bottom: 4.325699745547073vw;
            }
            & a {
              display: block;
              color: #FFF;
              text-align: left;
              font-family: Inter, sans-serif;
              font-size: calc(min(21px, 1.640625vw));
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-decoration: none;
              @media screen and (max-width: 767px) {
                font-size: 3.816793893129771vw;
              }
              &:hover {
                color: white;
                text-decoration: underline;
              }
            }
          }
        }
        &__contacts {
          @media screen and (max-width: 767px) {
            margin-top: 3.816793893129771vw;
          }
          &__title {
            color: #FFF;
            text-align: left;
            font-family: Inter, sans-serif;
            font-size: calc(min(21px, 1.640625vw));;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: calc(min(15px, 1.171875vw));
            @media screen and (max-width: 767px) {
              font-size: 4.071246819338422vw;
              margin-bottom: 4.325699745547073vw;
            }
          }
          &__item {
            text-align: left;
            margin-top: calc(min(15px, 1.171875vw));
            display: flex;
            align-items: center;
            &:first-child {
              margin-top: 0;
            }
            @media screen and (max-width: 767px) {
              margin-top: 4.325699745547073vw;
            }
            @media screen and (max-width: 767px) {
              text-align: left;
            }
            & img {
              margin-right: calc(min(10px, 0.78125vw));
              @media screen and (max-width: 767px) {
                margin-right: 2.5445292620865136vw;
                width: 3.816793893129771vw;
              }
            }
            & a {
              display: block;
              color: #FFF;
              text-align: left;
              font-family: Inter, sans-serif;
              font-size: calc(min(21px, 1.640625vw));
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-decoration: none;
              @media screen and (max-width: 767px) {
                font-size: 3.816793893129771vw;
              }
              &:hover {
                  color: white;
                  text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}
