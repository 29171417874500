.sidebar {
  background-color: #fff;
  border-radius: calc(min(5px, 0.390625vw)) 0px 0px calc(min(5px, 0.390625vw));
  min-height: calc(min(537px, 41.953125vw));
  &__list {
    &__item {
      display: flex;
      align-items: center;
      border-bottom: calc(min(1px, 0.078125vw)) solid rgba(131, 131, 131, 0.10);
      height: calc(min(50px, 3.90625vw));
      padding: 0 calc(min(16px, 1.25vw));
      text-decoration: none;
      &--active, &:hover {
        filter: invert(53%) sepia(11%) saturate(1261%) hue-rotate(99deg) brightness(91%) contrast(91%);
      }
      &__image {
        margin-right: calc(min(10px, 0.78125vw));
        width: calc(min(19px, 1.484375vw));
        filter: invert(53%) sepia(1%) saturate(0%) hue-rotate(245deg) brightness(97%) contrast(90%);
      }
      &__text {
        color: #838383;
        font-family: Inter, sans-serif;
        font-size: calc(min(14px, 1.09375vw));
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        white-space: nowrap;
        width: calc(min(135px, 10.546875vw));
        text-align: start;
      }
    }
  }
}