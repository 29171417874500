.cashier {
  background: #F2F6F8;
  padding: 19px 24px;
  min-width: calc( 100vw - 48px);
  min-height: calc( 100vh - 38px);
  max-width: 100vw;
  overflow-x: hidden;
  position: relative;
  &__connection {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
  &__head {
    display: flex;
    align-items: center;
    padding-bottom: 19px;
    position: relative;
    &__title {
      font-size: 14px;
      font-weight: 400;
      color: #838383;
    }
    &__logo {
      max-width: 115px;
      position: absolute;
      right: 50%;
      transform: translateX(50%);
      @media screen and (max-width: 744px) {
        right: 0;
        transform: none;
      }
    }
  }
  &__line {
    margin: 0;
    background: rgba(0, 0, 0, 0.10);
    height: 2px;
    width: 100%;
    border: none;
  }
  &__body {
    padding-top: 13px;
    display: flex;
    flex-wrap: wrap;
    gap: 23px;
  }
}
