@import "cabinet/auth";
@import "main/sidebar";
@import "cabinet/information";
@import "main/paymentNotification";
@import "cabinet/configuration";
@import "main/baseInput";
@import "main/baseButton";
@import "cabinet/synchronization";
@import "cabinet/templates";
@import "board/template_appetito";
@import "board/template_blue";
@import "board/template_full";
@import "board/template_original";
@import "main/colorPicker";
@import "cabinet/payment";
@import "main/baseToggle";
@import "cabinet/list";
@import "board/index";
@import "board/template";
@import "admin/list";
@import "main/paymentNotificationModal";
@import "main/policyModal";
@import "main/baseCheckbox";
@import "homePage";
@import "cabinet/faq";
@import "main/accordion";
@import "cabinet/contacts";
@import "privacyPolicy";
@import "cabinet/tariffs";
@import "cashier/index";
@import "cashier/cashierCard";
@import "main/baseSelect";
@import "main/loader";
@import "main/languageSelect";
@import "components/connectionStatus";
@import "cabinet/editTemplate";

.App {
  font-family: Arial, Helvetica, sans-serif;
  background: #F2F6F8;
}

input {outline:none;}

a:hover {
  color: #518C70;
}

.page {
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
}

.card-table {
  display: flex;
  justify-content: space-around;
  padding: calc(min(10px, 0.78125vw)) calc(min(30px, 2.34375vw));
  height: 100vh;
  background: seashell
}

.ready-card{
  color: green;
}

.verticale-line {
  width: calc(min(2px, 0.15625vw));
  background-color: black;
}

.table-header{
padding: calc(min(20px, 1.5625vw));
    font-weight: 800;
    font-size: 2rem;
}

.column-orientation {
  width: 100%;
}

.header {
  width: 100%;
  padding: calc(min(20px, 1.5625vw)) calc(min(67px, 5.234375vw));
  display: flex;
  align-items: center;
  background: #F7F7F7;
  box-shadow: 0px calc(min(1px, 0.078125vw)) calc(min(4px, 0.3125vw)) 0px rgba(0, 0, 0, 0.25);
  &__logo {
    width: calc(min(115px, 8.984375vw));
  }
  &__text {
    margin-left: calc(min(13px, 1.015625vw));
    color: #518C70;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: calc(min(20px, 1.5625vw));
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100%;
  margin-top: calc(min(39px, 3.046875vw));
  &__body {
    border-radius: calc(min(5px, 0.390625vw));
    background: #FFF;
    box-shadow: 0px calc(min(1px, 0.078125vw)) 0px 0px rgba(0, 0, 0, 0.25);
    padding: calc(min(22px, 1.7187500000000002vw)) calc(min(24px, 1.875vw));
    width: calc(min(836px, 65.3125vw));
    margin-bottom: calc(min(50px, 3.90625vw));
    &__content {
      border-radius: calc(min(5px, 0.390625vw));
      background: #F8F8F8;
      box-shadow: 0px calc(min(1px, 0.078125vw)) calc(min(4px, 0.3125vw)) 0px rgba(0, 0, 0, 0.25);
      height: 100%;
      display: flex;
    }
  }
}

.cards {
  // display: grid;
  // grid-gap: 20px;
  // grid-template-columns: 1fr 1fr 1fr 1fr;
  // padding: 40px;
  // @media screen and (max-width: 1200px) {
  //   grid-template-columns: 1fr 1fr 1fr;
  // }
  // @media screen and (max-width: 900px) {
  //   grid-template-columns: 1fr 1fr;
  // }
  // @media screen and (max-width: 600px) {
  //   grid-template-columns: 1fr;
  // }
  display: flex;
    width: 100%;
    height: 80%;
    // flex-direction: column;
    // display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0px;
}

.card {
    display: flex;
    flex-direction: inherit;
    // flex-direction: column;
    // width: 100%;
    // min-height: 300px;
    border-radius: calc(min(10px, 0.78125vw));
    &__title {
        margin: 0;
        font-size: calc(min(20px, 1.5625vw));
        font-weight: 600;
    }
    &__text {
        margin: 0;
        font-size: calc(min(16px, 1.25vw));
        font-weight: 400;
    }
    &__link {
        margin-top: auto;
        text-decoration: none;
        color: #555;
        font-size: calc(min(16px, 1.25vw));
        font-weight: 600;
        transition: color 0.3s;
        &:hover {
        color: #000;
        }
    }
    &__body {
      font-weight: 800;
      font-size: calc(min(22px, 1.7187500000000002vw));
      display: flex;
      flex-direction: column;
      // height: 100%;
      &__btn {
        justify-self: flex-end;
        margin-top: auto;
        padding: calc(min(10px, 0.78125vw)) calc(min(20px, 1.5625vw));
        border: none;
        border-radius: calc(min(5px, 0.390625vw));
        background-color: #555;
        color: #fff;
        font-size: calc(min(16px, 1.25vw));
        font-weight: 600;
        transition: background-color 0.3s;
        cursor: pointer;
        &:hover {
          background-color: #000;
        }
        &:disabled {
            background-color: #ccc;
            cursor: not-allowed;
        }
      }
    }
}
.connection {
  width: calc(min(7px, 0.546875vw));
  height: calc(min(7px, 0.546875vw));
  background-color: green;
  margin: calc(min(10px, 0.78125vw));
  border-radius: 50%;
  animation: pulse 1s infinite;
  &-error {
    background-color: red;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 calc(min(5px, 0.390625vw)) rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
