.payment {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  &__body {
    padding: calc(min(20px, 1.5625vw));
    display: flex;
    height: 100%;
    &__left {
      margin-right: calc(min(19px, 1.484375vw));
      height: 100%;
    }
    &__right {
      padding-top: calc(min(25px, 1.953125vw));
      display: flex;
      flex-direction: column;
        justify-content: space-between;
    }
    &__title {
      color: #518C70;
      font-family: Inter, sans-serif;
      font-size: calc(min(14px, 1.09375vw));
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: calc(min(10px, 0.78125vw));
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &-next {
        margin-top: calc(min(29px, 2.265625vw));
      }
      &__info {
        position: relative;
        height: calc(min(16px, 1.25vw));
        &:hover {
            .payment__body__title__info__text {
              display: block;
            }
        }
        & img {
          width: calc(min(16px, 1.25vw));
          margin-left: calc(min(5px, 0.390625vw));
        }
        &__text {
          position: absolute;
          display: none;
          left: 100%;
          bottom: 0;
          width: calc(min(231px, 18.046875vw));
          border-radius: calc(min(3px, 0.234375vw));
          background: #7C7C7C;
          padding: calc(min(9px, 0.703125vw));
          margin-left: calc(min(5px, 0.390625vw));
          color: #FFF;
          text-align: center;
          font-family: Inter, sans-serif;
          font-size: calc(min(10px, 0.78125vw));
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
    &__prices {
      border-radius:calc(min(3px, 0.234375vw));
      background: #FFF;
      box-shadow: 0px calc(min(2px, 0.15625vw)) calc(min(2px, 0.15625vw)) 0px rgba(0, 0, 0, 0.25);
      padding: 0 calc(min(11px, 0.8593750000000001vw));
      &__price {
        display: flex;
        align-items: center;
        padding: calc(min(7px, 0.546875vw)) 0;
        border-bottom: calc(min(1px, 0.078125vw)) solid rgba(0, 0, 0, 0.10);
        &:last-child {
            border-bottom: none;
        }
        &__sum {
          color: #585858;
          text-align: right;
          font-family: Inter, sans-serif;
          font-size: calc(min(16px, 1.25vw));
          font-style: normal;
          font-weight: 800;
          line-height: normal;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-left: calc(min(14px, 1.09375vw));
          & span {
            margin-top: calc(min(-2px, -0.15625vw));
            color: #585858;
            text-align: right;
            font-family: Inter, sans-serif;
            font-size: calc(min(9px, 0.703125vw));
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        &__description {
          color: #585858;
          font-family: Inter, sans-serif;
          font-size: calc(min(14px, 1.09375vw));
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-left: calc(min(27px, 2.109375vw));
        }
      }
    }
    &__block {
      border-radius: calc(min(3px, 0.234375vw));
      background: #FFF;
      box-shadow: 0px calc(min(2px, 0.15625vw)) calc(min(2px, 0.15625vw)) 0px rgba(0, 0, 0, 0.25);
      padding: calc(min(11px, 0.8593750000000001vw)) calc(min(13px, 1.015625vw)) calc(min(11px, 0.8593750000000001vw)) calc(min(11px, 0.8593750000000001vw));
      height: calc(min(283px, 22.109375vw));
      overflow-y: auto;
      &__item {
        display: flex;
        margin-bottom: calc(min(5px, 0.390625vw));
        &:last-child {
            margin-bottom: 0;
        }
        &__active {
          display: flex;
          align-items: center;
          padding: calc(min(3px, 0.234375vw)) calc(min(20px, 1.5625vw)) calc(min(3px, 0.234375vw)) 0;
          margin: calc(min(6px, 0.46875vw)) 0;
        }
        &__name {
          border-left: calc(min(1px, 0.078125vw)) solid rgba(0, 0, 0, 0.10);
          color: #838383;
          font-family: Inter, sans-serif;
          font-size: calc(min(12px, 0.9375vw));
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding: calc(min(3px, 0.234375vw)) calc(min(3px, 0.234375vw));
          border-right: calc(min(1px, 0.078125vw)) solid rgba(0, 0, 0, 0.10);
          display: flex;
          align-items: center;
          justify-content: center;
          max-width: calc(min(75px, 5.859375vw));
          width: 100%;
        }
        &__address {
          color: #838383;
          font-family: Inter, sans-serif;
          font-size: calc(min(12px, 0.9375vw));
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-left: calc(min(21px, 1.640625vw));
          display: flex;
          align-items: center;
          & span {
            width: calc(min(180px, 12.5vw));
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
    &__discount {
      color: #42B57F;
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: calc(min(10px, 0.78125vw));
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 100%;
      border-radius: calc(min(3px, 0.234375vw));
      background: rgba(66, 181, 127, 0.20);
      padding: calc(min(2px, 0.15625vw)) 0;
    }
    &__total {
      margin-top: calc(min(17px, 1.328125vw));
      display: flex;
      justify-content: space-between;
      padding-bottom: calc(min(3px, 0.234375vw));;
      border-bottom: calc(min(1px, 0.078125vw)) solid rgba(0, 0, 0, 0.10);
      &__description {
        color: #518C70;
        font-family: Inter, sans-serif;
        font-size: calc(min(14px, 1.09375vw));
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      &__sum {
        color: #585858;
        text-align: right;
        font-family: Inter, sans-serif;
        font-size: calc(min(20px, 1.5625vw));
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        & span {
          color: #585858;
          text-align: right;
          font-family: Inter, sans-serif;
          font-size: calc(min(14px, 1.09375vw));
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    &__total {
      &__group {
        margin-top: calc(min(18px, 1.40625vw));
      }
      &-description {
        margin-top: calc(min(8px, 0.625vw));
        display: flex;
        justify-content: space-between;
        &-hidden {
          opacity: 0;
        }
        &__description {
          color: #838383;
          font-family: Inter, sans-serif;
          font-size: calc(min(10px, 0.78125vw));
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        &__sum {
          color: #838383;
          text-align: right;
          font-family: Inter, sans-serif;
          font-size: calc(min(10px, 0.78125vw));
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
    &__pay {
      display: flex;
      justify-content: space-between;
      padding-top: calc(min(18px, 1.40625vw));
      margin-top: calc(min(18px, 1.40625vw));
      border-top: calc(min(1px, 0.078125vw)) solid rgba(0, 0, 0, 0.10);
      &__methods {
        display: flex;
        align-items: center;
        &__title {
          color: #838383;
          font-family: Inter, sans-serif;
          font-size: calc(min(10px, 0.78125vw));
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-right: calc(min(10px, 0.78125vw));
        }
        &__icons {
          display: flex;
          align-items: center;
          & img {
            max-width: calc(min(21px, 1.640625vw));
            max-height: calc(min(21px, 1.640625vw));
            margin-right: calc(min(14px, 1.09375vw));
          }
        }
      }
      &__btn {

      }
    }

  }
  &__footer {
    text-align: left;
    color: #518C70;
    font-family: Inter, sans-serif;
    font-size: calc(min(12px, 0.9375vw));
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 0 0 calc(min(5px, 0.390625vw)) 0;
    background: #DBE6DD;
    padding: calc(min(13px, 1.015625vw)) calc(min(30px, 2.34375vw));
    width: calc(min(580px, 45.3125vw));
  }
}