.faq {
  padding-top: calc(min(19px, 1.484375vw));
  padding-left: calc(min(21px, 1.640625vw));
  position: relative;
  width: calc(min(620px, 48.4375vw));
  &__title {
    color: #518C70;
    font-family: Inter, sans-serif;
    font-size: calc(min(14px, 1.09375vw));
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
    margin-bottom: calc(min(6px, 0.46875vw));
  }
  &__accordions {
    padding-right: calc(min(18px, 1.40625vw));
    padding-top: calc(min(10px, 0.78125vw));
    height: calc(min(440px, 34.375vw));
    overflow-y: auto;
    overflow-x: hidden;
  }
  &__footer {
    padding: 0 calc(min(21px, 1.640625vw));
    width: calc(100% - calc(min(42px, 3.28125vw)));
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0px 0px calc(min(5px, 0.390625vw)) 0px;
    background: #D4CED8;
    &__text {
      color: #422757;
      font-family: Inter, sans-serif;
      font-size: calc(min(12px, 0.9375vw));
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &__link {
      cursor: pointer;
      display: flex;
      align-items: center;
      color: var(--Secondary, #422757);
      font-family: Inter, sans-serif;
      font-size: calc(min(12px, 0.9375vw));
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      height: calc(min(31px, 2.421875vw));
      text-decoration: none;
      &:hover {
        color: var(--Secondary, #422757);
      }
      & span {
        border-bottom: 1px solid var(--Secondary, #422757);
        display: flex;
        align-items: center;
      }
    }
  }
}