.payment-notification {
  padding: calc(min(5px, 0.390625vw));
  color: #A98484;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: calc(min(12px, 0.9375vw));
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #EBE1E1;
  & a {
    color: #A98484;
    font-family: Inter, sans-serif;
    font-size: calc(min(10px, 0.78125vw));
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}