.privacy-policy {
  font-family: 'Francois One', sans-serif;
  &__head {
    background: linear-gradient(282deg, #518C70 -10.86%, #F2F6F8 94.33%);
    &__content {
      padding: calc(min(35px, 2.734375vw)) 0;
      max-width: calc(min(1066px, 83.28125vw));
      margin: 0 auto;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      position: relative;
      &__logo {
        & img {
          width: 209px
        }
      }
    }
  }
  &__body {
    &__content {
      padding: calc(min(40px, 3.125vw)) 0;
      max-width: calc(min(1066px, 83.28125vw));
      margin: 0 auto;
      &__head {
        padding-bottom: 40px;
        border-bottom: 1px solid #518C70;
        &__btn {
          display: flex;
          align-items: center;
          color: var(--Main, #518C70);
          text-align: center;
          font-family: Inter, sans-serif;
          font-size: 23px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          cursor: pointer;
          text-decoration: none;
        }
      }
      &__title {
        margin: 42px 0;
        color: #000;
        font-family: Inter, sans-serif;
        font-size: 23px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: left;
      }
      &__subtitle {
        color: #000;
        font-family: Inter, sans-serif;
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: left;
      }
      &__text {
        color: var(--Dark-gray, #585858);
        text-align: justify;
        font-family: Inter, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        & p {
          color: var(--Dark-gray, #585858);
          font-family: Inter, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px; /* 162.5% */
        }
        & ul, ol {
          @media screen and (max-width: 767px) {
            padding-left: 15px;
            font-size: 16px;
          }
        }
        & li {
            margin-bottom: 2px;

        }
        & ol {
          color: var(--Dark-gray, #585858);
          font-family: Inter, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          & li {
            margin-bottom: 10px;
            & a {
              color: var(--Secondary, #422757);
              font-family: Inter, sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
                text-decoration: none;
            }
          }
        }
      }
      &__video {
        margin-bottom: 42px;
        &__title {
          color: var(--Secondary, #422757);
          font-family: Inter, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-align: left;
          & a {
            color: var(--Secondary, #422757);
            font-family: Inter, sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-align: left;
            text-decoration: none;
          }
        }
      }
    }

  }
  &__footer {
    background: var(--Main, #518C70);
    &__content {
      padding: calc(min(72px, 5.625vw)) 0;
      max-width: calc(min(1066px, 83.28125vw));
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: 767px) {
        flex-direction: column;
        padding-top: 4.580152671755725vw;
        padding-bottom: 13.740458015267176vw;
        max-width: 84.22391857506362vw;
      }
      &__logo {
        display: flex;
        color: var(--Text-light, #FFF);
        text-align: center;
        font-family: Francois One, sans-serif;
        font-size: calc(min(41px, 3.2031249999999996vw));
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        @media screen and (max-width: 767px) {
          font-size: 7.888040712468193vw;
        }
        & img {
          margin-right: calc(min(10px, 0.78125vw));
          width: calc(min(263px, 20.546875vw));
          margin-bottom: calc(min(10px, 0.78125vw));
          @media screen and (max-width: 767px) {
            width: 35.6234096692112vw;
            margin: 0 2.5445292620865136vw 0 0;
          }
        }
      }
      &__text {
        display: flex;
        @media screen and (max-width: 767px) {
          flex-direction: column;
          margin-top: 7.633587786259542vw;
          width: 100%;
        }
        &__links {
          margin: 0;
          padding-left: 0;
          padding-right: calc(min(104px, 8.125vw));
          @media screen and (max-width: 767px) {
            padding-right: 0;
            width: 100%;
            border-bottom: calc(min(1px, 0.078125vw)) solid rgba(255, 255, 255, 0.20);
          }
          &__link {
            list-style-type: none;
            text-align: left;
            margin-bottom: calc(min(15px, 1.171875vw));
            @media screen and (max-width: 767px) {
              width: 100%;
              margin-bottom: 4.325699745547073vw;
            }
            & a {
              color: #FFF;
              text-align: left;
              font-family: Inter, sans-serif;
              font-size: calc(min(21px, 1.640625vw));
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-decoration: none;
              @media screen and (max-width: 767px) {
                font-size: 3.816793893129771vw;
              }
              &:hover {
                color: white;
                text-decoration: underline;
              }
            }
          }
        }
        &__contacts {
          @media screen and (max-width: 767px) {
            margin-top: 3.816793893129771vw;
          }
          &__title {
            color: #FFF;
            text-align: left;
            font-family: Inter, sans-serif;
            font-size: calc(min(21px, 1.640625vw));;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: calc(min(15px, 1.171875vw));
            @media screen and (max-width: 767px) {
              font-size: 4.071246819338422vw;
              margin-bottom: 4.325699745547073vw;
            }
          }
          &__item {
            text-align: left;
            margin-top: 15px;
            &:first-child {
              margin-top: 0;
            }
            @media screen and (max-width: 767px) {
              margin-top: 4.325699745547073vw;
            }
            @media screen and (max-width: 767px) {
              text-align: left;
            }
            & img {
              margin-right: calc(min(10px, 0.78125vw));
              @media screen and (max-width: 767px) {
                margin-right: 2.5445292620865136vw;
                width: 3.816793893129771vw;
              }
            }
            & a {
              color: #FFF;
              text-align: left;
              font-family: Inter, sans-serif;
              font-size: calc(min(21px, 1.640625vw));
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-decoration: none;
              @media screen and (max-width: 767px) {
                font-size: 3.816793893129771vw;
              }
              &:hover {
                  color: white;
                  text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}