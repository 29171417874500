.synchronization {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  &__body {
    padding: calc(min(14px, 1.09375vw)) calc(min(14px, 1.09375vw));
    &__head {
      display: flex;
      &__tariff {
        background: #FFFFFF;
        display: flex;
        align-items: center;
        height: calc(min(23px, 1.7968749999999998vw));
        width: calc(min(235px, 18.359375vw));
        border-radius: calc(min(3px, 0.234375vw));
        padding: calc(min(10px, 0.78125vw));
        &__img {
          width: calc(min(20px, 1.5625vw));
        }
        &__text {
            margin-left: calc(min(10px, 0.78125vw));
            color: #585858;
            font-family: Inter, sans-serif;
            font-size: calc(min(14px, 1.09375vw));
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            height: max-content;
        }
      }
      &__locations {
        background: #FFFFFF;
        width: calc(min(140px, 10.9375vw));
        height: calc(min(23px, 1.7968749999999998vw));
        display: flex;
        align-items: center;
        border-radius: calc(min(3px, 0.234375vw));
        padding: calc(min(10px, 0.78125vw));
        margin-left: calc(min(10px, 0.78125vw));
        &__text {
            color: #585858;
            font-family: Inter, sans-serif;
            font-size: calc(min(14px, 1.09375vw));
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            height: max-content;
        }
      }
      &__info {
        display: flex;
        align-items: center;
        font-family: Inter, sans-serif;
        font-size: calc(min(14px, 1.09375vw));
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: auto;
        & a {
          color: #518C70;
          display: flex;
          align-items: center;
          text-decoration: none;
          padding-bottom: calc(min(2px, 0.15625vw));
          border-bottom: 1px solid #518C70;
        }
        &__img {
          transform: rotate(180deg);
          width: calc(min(13px, 1.015625vw));
          margin-left: calc(min(5px, 0.390625vw));
        }
      }
      &__tariff-info {
        display: flex;
        align-items: center;
        margin-top: calc(min(10px, 0.78125vw));
        margin-bottom: calc(min(17px, 1.328125vw));
        &__img {
          width: calc(min(20px, 1.5625vw));
        }
        &__text {
          margin-left: calc(min(5px, 0.390625vw));
          color: #585858;
          font-family: Inter, sans-serif;
          font-size: calc(min(14px, 1.09375vw));
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          height: max-content;
        }
      }
    }
    &__block {
      opacity: .6;
      margin-top: calc(min(10px, 0.78125vw));
      border-radius: calc(min(3px, 0.234375vw));
      background: #FFF;
      box-shadow: 0 0 calc(min(1px, 0.078125vw)) 0 rgba(0, 0, 0, 0.25);
      display: flex;
      align-items: center;
      width: calc(min(582px, 45.46875vw));
      padding: calc(min(7px, 0.546875vw)) calc(min(13px, 1.015625vw));
      &-active {
        opacity: 1;
        .synchronization__body__block__main__id, .synchronization__body__block__main__text {
          color: #518C70;
        }
        .synchronization__body__block__img {
          filter: invert(53%) sepia(11%) saturate(1261%) hue-rotate(99deg) brightness(91%) contrast(91%);
        }
      }
      &-no-selected {
        opacity: 1;
      }
      &:first-child {
        margin-top: 0;
      }
      &__img {
        width: calc(min(28px, 2.1875vw));
        filter: invert(34%) sepia(8%) saturate(16%) hue-rotate(338deg) brightness(92%) contrast(84%);
      }
      &__name {
        margin-left: calc(min(12px, 0.9375vw));
        &__value {
          color: #585858;
          font-family: Inter, sans-serif;
          font-size: calc(min(14px, 1.09375vw));
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          width: calc(min(65px, 5.078125vw));
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        &__id {
          color: #838383;
          font-family: Inter, sans-serif;
          font-size: calc(min(14px, 1.09375vw));
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      &__line {
        height: calc(min(55px, 4.296875vw));
        width: calc(min(1px, 0.078125vw));
        background: #E7E7E7;
        margin: 0 calc(min(15px, 1.171875vw)) 0 calc(min(12px, 0.9375vw));
      }
      &__main {
        align-items: center;
        &__row {
        }
        &__id {
          color: #585858;
          font-family: Inter, sans-serif;
          font-size: calc(min(12px, 0.9375vw));
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin-left: calc(min(10px, 0.78125vw));
          margin-top: calc(min(-2px, -0.15625vw));
        }
        &__text {
          color: #585858;
          text-align: start;
          font-family: Inter, sans-serif;
          font-size: calc(min(10px, 0.78125vw));
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      &__end {
        margin: 0 0 0 auto;
        &__message {
          max-width: calc(min(170px, 13.28125vw));
          color: #585858;
          text-align: right;
          font-family: Inter, sans-serif;
          font-size: calc(min(12px, 0.9375vw));
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          & a {
            color: inherit;
          }
        }
      }
    }
  }
  &__footer {
    width: calc(100% - calc(min(45px, 3.515625vw)));
    padding: calc(min(10px, 0.78125vw)) calc(min(23px, 1.7968749999999998vw));
    background: rgba(66, 39, 87, 0.20);
    font-family: Inter, sans-serif;
    font-size: calc(min(12px, 0.9375vw));
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #422757;
    & a {
      margin-left: calc(min(10px, 0.78125vw));
      font-weight: 500;
    }
    &:last-child{
      margin-bottom: calc(min(13px, 1.015625vw));
    }
  }
}
