.base-input {
  border-radius: calc(min(2px, 0.15625vw));
  background: #FFF;
  box-shadow: 0px 0px calc(min(2px, 0.15625vw)) 0px rgba(0, 0, 0, 0.25);
  border: none;
  color:  #838383;
  font-family: Inter, sans-serif;
  font-size: calc(min(12px, 0.9375vw));
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: calc(100% - calc(min(22px, 1.7187500000000002vw)));
  height: calc(min(38px, 2.96875vw));
  padding: 0 calc(min(11px, 0.8593750000000001vw));
  &-error {
    box-shadow: 0 0 calc(min(2px, 0.15625vw)) 0 #FF0000;
  }
}