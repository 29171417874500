@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.templates {
  --initial-font-size: 3.90625vw;

  --in-progress-background: #2F50E5;
  --in-progress-head-color: #FFFFFF;
  --in-progress-number-color: #FFFFFF;
  --in-progress-more-color: #FFFFFF;

  --done-background: #CAEFF8;
  --done-head-color: #2F50E5;
  --done-number-color: #2F50E5;
  --done-more-color: #2F50E5;

  //--head-font-size: .16em;
  --head-font-size: 1.08em;

  --number-font-size: 5.015625vw;
  //--number-font-size: .93vw;

  --count-columns: 3;

  --more-font-size: .34em;

  &__text--active {
    text-decoration: underline;
  }

  &__blue {
    position: relative;
    font-family: "Inter", sans-serif;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    text-align: start;
    font-size: var(--initial-font-size);

    &__in_progress {
      background: var(--in-progress-background);

      .templates__blue__block__head {
        color: var(--in-progress-head-color);
        font-weight: 900;
      }

      .templates__blue__block__numbers {
        color: var(--in-progress-number-color);
      }

      .templates__more {
        color: var(--in-progress-more-color);
        font-size: var(--more-font-size);
      }
    }

    &__done {
      background: var(--done-background);

      .templates__blue__block__head {
        color: var(--done-head-color);
        font-weight: 800;
      }

      .templates__blue__block__numbers {
        color: var(--done-number-color);
      }

      .templates__more {
        color: var(--done-more-color);
        font-size: var(--more-font-size);
      }

    }

    &__block {
      width: 50%;
      height: calc(100% - 0.78125% - 2.5%);
      padding: 0.78125% 2.5% 2.5%;

      &__head {
          font-size: var(--head-font-size);
      }

      &__numbers {
        margin-top: 1.7187500000000002%;
        font-size: var(--number-font-size);
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        height: calc(91% - 30px);
        overflow: hidden;

        &__number {
          width: calc(100% / var(--count-columns));
          font-weight: 900;
        }
      }
    }
  }
}

.hide_block {
  opacity: 0;
}
