.template_edit {
  position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  &__modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 45.46875vw;
    height: 25.3125vw;
    background-color: #f8f8f8;
    border-radius: 0.78125vw;
    padding: 1.5625vw;
    &__body {
      display: flex;
      justify-content: space-between;
      &__left {
        &__template {
          width: 22.265625vw;
          height: 15.546874999999998vw;
          cursor: pointer;
        }
        &__description {
          font-size: 0.78125vw;
          color: #585858;
          text-align: left;
          margin-top: 1.328125vw;
        }
      }
      &__right {
        &__description {
            font-size: 0.78125vw;
            color: #585858;
            text-align: left;
            margin-top: 1.328125vw;
        }
      }
    }
    &__footer {
      display: flex;
      justify-content: space-between;
      &__instruction {
        display: flex;
        align-items: center;
        padding: 0.078125vw 0.46875vw;
        border: 0.078125vw solid #585858;
        border-radius: 0.46875vw;
        cursor: pointer;
        transition: all 0.2s;
        &__icon {
          width: 1.875vw;
          height: 1.875vw;
          margin-right: 0.3125vw;
        }
        &__text {
          font-size: 0.9375vw;
          color: #585858;
        }
        &:hover {
          background-color: #58585815;
          border-color: #58585870;
        }
      }
      &__buttons {
        display: flex;
        font-size: 0.9375vw;
        font-family: "Inter", sans-serif;
        color: white;
        font-weight: bold;
        cursor: pointer;
        transition: all 0.2s;
        &__button {
          background-color: #518C70;
          padding: 0.546875vw;
          border-radius: 0.46875vw;
          border: 0.078125vw solid #518C70;
          &:hover {
            opacity: 0.9;
          }
          &-cancel {
            margin-right: 0.78125vw;
            color: #518C70;
            background-color: white;
          }
        }
      }
    }
  }
}
